import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

export default new Vuex.Store({
  state: {
    eventUseFlag: true,
    transferUseFlag: true,
    transferMasterUseFlag: true,
    conditionManagementUseFlag: false,
    positionUseFlag: true,
    trialUseFlag: true,
    adultClassUseFlag: true,
    jleagueIdUseFlag: true,
    annualUpdateUseFlag: true,
    isSp: true,
    isTablet: false,
    isAdmin: window.localStorage.getItem('isAdmin') !== 'false',
    registerStatus: false,
    accountType: 0, // 1=生徒 2=保護者 3=コーチ 4=請求担当 5=管理者 6=ぴあ権限
    isMock: false, // window.localStorage.getItem('isMock') !== 'false',
    barState: 'close',
    barStateFlag: false,
    logined: false,
    loginId: '',
    userId: '',
    loginName: '',
    loginMail: '',
    loginUserId: null,
    jid: '',
    requestType: '',
    topNoticeDialog: false,
    loading: [],
    openNews: null,
    jleagueLoginTo: '',
    gmoCardInfo: {},
    fromPage: {},
    transferReasonMaster: [],
    nextYearUpdateStartDate: '',
    nextYearUpdateEndDate: '',
    newsInfo: {
      notices: []
    },
    requestInfo: {
      schoolId: null,
      schoolName: null,
      courseId: null,
      courseName: null,
      classId: null,
      lessonId: null
    },
    editDeleteDialog: {
      show: false,
      resetForm: null,
      afterFunction: null
    },
    snackbar: {
      show: false,
      color: '',
      text: ''
    },
    lessonMaster: {},
    lessonMasterAll: {},
    lessonRelation: [],
    lessonRelationAll: [],
    lessonRelationRequestAvailable: [],
    lessonRelationYearUpdateOnly: [],
    lessonRelationAdminOnly: [],
    billClosingDateMaster: [],
    myCourse: null,
    paymentTypeList: [],
    requestEventType: null,
    maincontentEl: null,
    pageEl: null,
    pageHeaderEl: null,
    pageBodyEl: null,
    pageFooterEl: null
  },
  getters: {
    getIsSp: state => {
      return state.isSp
    }
  },
  mutations: {
    toggleIsMock: function (state) {
      state.isMock = !state.isMock
      window.localStorage.setItem('isMock', state.isMock.toString())
    },
    setIsAdmin: function (state, type) {
      state.isAdmin = type
      window.localStorage.setItem('isAdmin', type.toString())
    },
    setDeviceType: function (state, width) {
      console.log(width)
      state.isSp = window.innerWidth < width.sm
      state.isTablet = window.innerWidth < width.md
    },
    setLogined: function (state, logined) {
      state.logined = logined
    },
    startLoading: function (state) {
      state.loading.push(true)
    },
    finishLoading: function (state) {
      state.loading.pop()
    },
    initLoading: function (state) {
      state.loading = []
    },
    setBarState: function (state, stateString) {
      state.barState = stateString
      state.barStateFlag = state.barState === 'open'
    },
    setSiteType: function (state, type) {
      state.isPortal = type
    },
    setLoginData: function (state, info) {
      console.log(info)
      state.loginId = info.id
      state.userId = info.userId
      state.loginName = info.name
      state.loginMail = info.mail
      state.jid = info.jid
    },
    setLogout: function (state) {
      state.loginId = ''
      state.userId = ''
      state.loginName = ''
      state.loginMail = ''
      state.jid = ''
      state.myCourse = null
    },
    setTopNoticeDialog: function (state, flag) {
      state.topNoticeDialog = flag
    },
    seteditDeleteDialog: function (state, info) {
      state.editDeleteDialog.show = !!info
      if (info) {
        state.editDeleteDialog.resetForm = info.resetForm
        state.editDeleteDialog.afterFunction = info.function
      } else {
        state.editDeleteDialog.resetForm = null
        state.editDeleteDialog.afterFunction = null
      }
    },
    setResetFormObject: function (state, info) {
      state.editDeleteDialog.resetFormObject = info
      console.log(state.editDeleteDialog.resetFormObject)
    },
    setSnackbar: function (state, info) {
      state.snackbar.show = !!info
      state.snackbar.text = info.text
      state.snackbar.color = info.color
    },
    setRequestType: function (state, type) {
      state.requestType = type
    },
    setRequestSchoolId: function (state, info) {
      state.requestInfo.schoolId = info.id
      state.requestInfo.schoolName = info.name
      window.localStorage.setItem('schoolId', info.id)
      window.localStorage.setItem('schoolName', info.name)
    },
    setRequestCourseId: function (state, info) {
      state.requestInfo.courseId = info.id
      state.requestInfo.courseName = info.name
      window.localStorage.setItem('courseId', info.id)
      window.localStorage.setItem('courseName', info.name)
    },
    setRequestClassId: function (state, id) {
      state.requestInfo.classId = id
      window.localStorage.setItem('classId', id)
    },
    setRequestLessonId: function (state, id) {
      state.requestInfo.lessonId = id
      window.localStorage.setItem('lessonId', id)
    },
    setJleagueLoginTo: function (state, url) {
      state.jleagueLoginTo = url
      window.localStorage.setItem('jleagueLoginTo', url)
    },
    setUserStatus: function (state, info) {
      state.registerStatus = info.register_status
      state.accountType = info.user_classification
      state.isAdmin = info.user_classification >= 3
      window.localStorage.setItem('isAdmin', state.isAdmin.toString())
    },
    setInitUserStatus: function (state) {
      state.registerStatus = false
      state.accountType = 0
    },
    setGmoCardInfo: function (state, info) {
      state.gmoCardInfo = info
    },
    setNewsInfo: function (state, info) {
      console.log(info)
      state.newsInfo = info
    },
    setLessonMaster: function (state, master) {
      console.log(master)
      state.lessonMasterAll = master
      state.lessonMaster = Object.keys(master).length > 0 ? {
        school: master.school.filter(v => v.is_delete === 0),
        course: master.course.filter(v => v.is_delete === 0),
        class: master.class.filter(v => v.is_delete === 0),
        lesson: master.lesson.filter(v => v.is_delete === 0)
      } : {}
    },
    setLessonRelation: function (state, relation) {
      // number_of_students: string | number;参加者
      // number_of_join: string | number;翌月入会者
      // number_of_recess: string | number;翌月休会者
      // number_of_withdrawal: string | number;翌月退会者
      // number_of_join_ANM: string | number;翌々月以降入会者
      // number_of_recess_ANM: string | number;翌々月以降休会者
      // number_of_withdrawal_ANM: string | number;翌々月以降退会者
      // number_of_waiting: string | number;キャンセル待ち人数
      console.log(relation)
      state.lessonRelation = relation.filter(v => v.is_expired === 0)
      state.lessonRelationAll = relation
      state.lessonRelationRequestAvailable = relation.filter(v => v.is_expired === 0 && v.reception_status <= 2)
      state.lessonRelationYearUpdateOnly = relation.filter(v => v.is_expired === 0 && (v.reception_status <= 2 || v.reception_status === 4))
      state.lessonRelationcAdminOnly = relation.filter(v => v.is_expired === 0 && v.reception_status !== 3)
    },
    setMyCourse: function (state, lessons) {
      state.myCourse = lessons
    },
    setBillClosingDateMaster: function (state, response) {
      state.billClosingDateMaster = response.master.bill_closing_date
    },
    setPaymentTypeList: function (state, info) {
      state.paymentTypeList = info
    },
    setFromPageInfo: function (state, info) {
      console.log(info)
      state.fromPage = info
    },
    setTransferReasonMaster: function (state, list) {
      state.transferReasonMaster = list.sort((a, b) => a.reason_category_order_no > b.reason_category_order_no ? 1 : -1).map(v => {
        return {
          value: v.id,
          text: v.reason_category_name
        }
      })
    },
    setNextYearUpdateDateSpan: function (state, info) {
      state.nextYearUpdateStartDate = 'nextYearStartDate' in info ? info.nextYearStartDate : null
      state.nextYearUpdateEndDate = 'nextYearEndDate' in info ? info.nextYearEndDate : null
    },
    setEventType: function (state, type) {
      state.requestEventType = type
    },
    setLoginUserId: function (state, id) {
      state.loginUserId = id
    },
    setMaincontentEl: function (state, elements) {
      state.maincontentEl = elements.maincontent
    },
    setPageEl: function (state, elements) {
      state.pageEl = elements.page
      state.pageHeaderEl = elements.pageHeader
      state.pageBodyEl = elements.pageBody
      state.pageFooterEl = elements.pageFooter
    },
    openNews: function (state, id) {
      state.openNews = id
    },
    closeNews: function (state) {
      state.openNews = null
    },
    login (state, token) {
      state.token = token
    },
    logout (state) {
      state.token = null
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState({
    key: 'TodoApp',
    paths: ['token'],
    storage: window.localStorage
  })]
})
